<template>
  <preliminary-results-template
    :personal-fields="personalFields"
    :photo-diagnostic-results="photoAnalysisDiagnostic"
    :patient-photo="patientPhoto"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import PreliminaryResultsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/photo-analysis-results/preliminary-results/PreliminaryResultsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { types as questTypes } from '@/modules/questionnaire/store/types';

const { field, requiredField } = fieldBuilder;

const PERSONAL_FIELDS = [
  'ageGroup',
  'gender',
  'phototype',
  'dateOfBirth',
  'skinThickness',
  'skinReactsToProducts',
  'skinTypeNorDryOilyComb'
];
const REQUIRED_FIELDS = ['age'];

const FIELDS = [...PERSONAL_FIELDS.map(field), ...REQUIRED_FIELDS.map(requiredField)];

export default {
  name: 'PreliminaryResults',
  components: {
    PreliminaryResultsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    ...mapState({
      patientPhoto: state => state.questionnaire.patientPhoto,
      photoAnalysisDiagnostic: state => state.questionnaire.photoAnalysisDiagnostic
    }),
    personalFields() {
      return [...PERSONAL_FIELDS, ...REQUIRED_FIELDS].reduce(
        (fields, fieldName) => ({ ...fields, [fieldName]: this[fieldName] }),
        {}
      );
    }
  },
  mounted() {
    if (!this.patientPhoto) {
      this.fetchPatientQuestionnairePhoto();
    }
  },
  methods: {
    ...mapActions({
      fetchPatientQuestionnairePhoto: questTypes.actions.FETCH_PATIENT_QUESTIONNAIRE_PHOTO
    })
  }
};
</script>
